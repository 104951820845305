<template>
  <div id="securitysetting">
    <div class="titleLine">
      <progress-circle-bar :value="progress" size= 60></progress-circle-bar>
      <div class="titleBox">
        <h1>{{$t(`lang.security`)}}</h1>
        <h3>{{$t(`lang.security1`)}}</h3>
      </div>
    </div>

    <div class="content">
      <div class="menu">
        <div class="title">{{$t(`lang.tWOSTEP`)}}</div>
        <menu-option-item height= "40" :textStart= textStart>
          <template v-slot:title><input class="checkbox" type="checkbox" v-model="securitySettingForm.twoStep" @click="btntwoStep"></template>
          <template v-slot:value>{{$t(`lang.tWOSTEP`)}}</template>
        </menu-option-item>

        <div class="commonEquipmentBox">
          <div class="commonTitle">{{$t(`lang.commonEquipment`)}}</div>
          <div class="commonItem"></div>
          <div class="commonItem"></div>
        </div>
        <div class="title">{{$t(`lang.passwordHint`)}}</div>
        <div class="title" @click="clickPopShow1(index)">{{securitySettingForm.passwordQuestion1}}</div>
        <div class="title" @click="clickPopShow2(index)">{{securitySettingForm.passwordQuestion2}}</div>
        <div class="title" @click="clickPopShow3(index)">{{securitySettingForm.passwordQuestion3}}</div>
      </div>


      <div class="menu">
        <div class="title">{{$t(`lang.security`)}}</div>
        <menu-option-item widthTitle="30%">
          <template v-slot:title>{{$t(`lang.changePassword`)}}</template>
          <template v-slot:value></template>
          <template v-slot:btn><svg-icon name="toright" color="#fff" @click="goChangePassword()"></svg-icon></template>
        </menu-option-item>

        <menu-option-item widthTitle="30%">
          <template v-slot:title>{{$t(`lang.changeEmail`)}}</template>
          <template v-slot:value>{{securitySettingForm.email}}</template>
          <template v-slot:btn><svg-icon name="toright" color="#fff" @click="goChangeEmail()"></svg-icon></template>
        </menu-option-item>

        <menu-option-item widthTitle="30%">
          <template v-slot:title>{{$t(`lang.changeMobile`)}}</template>
          <template v-slot:value>{{securitySettingForm.mobile}}</template>
          <template v-slot:btn><svg-icon name="toright" color="#fff"></svg-icon></template>
        </menu-option-item>

        <div class="title">{{$t(`lang.privacySetting`)}}</div>
        <menu-option-item height= "40" :textStart= textStart>
          <template v-slot:title><input class="checkbox" type="checkbox" v-model="securitySettingForm.publicEmail" @click="btnPublicEmail"></template>
          <template v-slot:value>{{$t(`lang.privacySetting1`)}}</template>
        </menu-option-item>
        <menu-option-item height= "40" :textStart= textStart>
          <template v-slot:title><input class="checkbox" type="checkbox" v-model="securitySettingForm.publicInfo" @click="btnpublicInfo"></template>
          <template v-slot:value>{{$t(`lang.privacySetting2`)}}</template>
        </menu-option-item>
        <menu-option-item height= "40" :textStart= textStart>
          <template v-slot:title><input class="checkbox" type="checkbox" v-model="securitySettingForm.publicTrading" @click="btnpublicTrading"></template>
          <template v-slot:value>{{$t(`lang.privacySetting3`)}}</template>
        </menu-option-item>
        <menu-option-item height= "40" :textStart= textStart>
          <template v-slot:title><input class="checkbox" type="checkbox" v-model="securitySettingForm.publicTradingHistory" @click="btnPpublicTradingHistory"></template>
          <template v-slot:value>{{$t(`lang.privacySetting4`)}}</template>
        </menu-option-item>
      </div>      
    </div>

    <div class="btnBox">
      <btn-submit class="btnSave" color= "blue" @click="back">Back</btn-submit>
      <btn-submit class="btnSave" color= "red" @click="saveSafeSetting()">Save</btn-submit>
    </div>
  </div>
  <pop-password-question ref="popWindow" class="popWindow" @returnForm="returnForm"></pop-password-question>
</template>

<script>
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
import MenuOptionItem from '../components/MenuOptionItem.vue'
import BtnSubmit from '@/components/button/BtnSubmit.vue'
import ProgressCircleBar from '@/components/progressBar/ProgressCircleBar.vue'

import { getSecuritySetting,updateSecuritySetting } from "@/axios/api/setting"
import PopPasswordQuestion from '../components/PopPasswordQuestion.vue'
export default {
  name:'SecuritySetting',
  components: {
    ProgressCircleBar,
    BtnSubmit,
    MenuOptionItem,
    SvgIcon,
    PopPasswordQuestion
  },
  data() {
    return {
      textStart: true,
      progress:10,
      securitySettingForm:'',

      whichQuestion:Number,
    }
  },
  methods:{
    back(){
      this.$router.go(-1)
    },
    goChangePassword(){
      this.$router.push("/usercenter/setting/changePassword")
    },
    goChangeEmail(){
      this.$router.push("/usercenter/setting/changeEmail")
    },
    btnPublicEmail(){
      this.securitySettingForm.publicEmail = !this.securitySettingForm.publicEmail
    },
    btnpublicInfo(){
      this.securitySettingForm.publicInfo = !this.securitySettingForm.publicInfo
    },
    btnpublicTrading(){
      this.securitySettingForm.publicTrading = !this.securitySettingForm.publicTrading
    },
    btnPpublicTradingHistory(){
      this.securitySettingForm.publicTradingHistory = !this.securitySettingForm.publicTradingHistory
    },
    btntwoStep(){
      this.securitySettingForm.twoStep = !this.securitySettingForm.twoStep
      console.log(this.securitySettingForm.twoStep)
    },
    clickPopShow1(){
      if(this.securitySettingForm.passwordQuestion1 == "Question One") {
        this.$refs.popWindow.isShow=false
      } else {
        this.$refs.popWindow.isShow=true
      }
      this.$refs.popWindow.popShow=true
      this.whichQuestion = 1
    },
    clickPopShow2(){
      if(this.securitySettingForm.passwordQuestion2 == "Question Two") {
        this.$refs.popWindow.isShow=false
      } else {
        this.$refs.popWindow.isShow=true
      }
      this.$refs.popWindow.popShow=true
      this.whichQuestion = 2
    },
    clickPopShow3(){
      if(this.securitySettingForm.passwordQuestion3 == "Question Three") {
        this.$refs.popWindow.isShow=false
      } else {
        this.$refs.popWindow.isShow=true
      }
      this.$refs.popWindow.popShow=true
      this.whichQuestion = 3
    },
    saveSafeSetting(){
      updateSecuritySetting(this.securitySettingForm).then(res => {
        if(res.code == 20000){
          window.location.href = '/usercenter'
        }
      })
    },
    returnForm(item){
      if(this.whichQuestion == 1){
        this.securitySettingForm.passwordQuestion1 = item.btnText
        this.securitySettingForm.passwordAnswer1 = item.answerAgain
      }
      else if(this.whichQuestion == 2){
        this.securitySettingForm.passwordQuestion2 = item.btnText
        this.securitySettingForm.passwordAnswer2 = item.answerAgain
      }
      else{
        this.securitySettingForm.passwordQuestion3 = item.btnText
        this.securitySettingForm.passwordAnswer3 = item.answerAgain
      }
    }
  },
  mounted(){
    getSecuritySetting().then(res=>{
      this.securitySettingForm = res.data.securitySetting
      if(this.securitySettingForm.passwordQuestion1==""){
        this.securitySettingForm.passwordQuestion1 = "Question One"
      }
      if(this.securitySettingForm.passwordQuestion2==""){
        this.securitySettingForm.passwordQuestion2 = "Question Two"
      }
      if(this.securitySettingForm.passwordQuestion3==""){
        this.securitySettingForm.passwordQuestion3 = "Question Three"
      }
    })
  }
}
</script>

<style scoped>
#securitysetting{
  width: 100%;
  height: 100%;
}
.titleLine{
  position: relative;
  height: 170px;
  width: 100%;
  display: flex;
  color: #fff;
}
.titleBox{
  margin-left: 60px;
  width: 50%;
}
h3{
  color: rgb(187, 187, 187);
}
h1{
  font-size: 32px;
}
h2{
  font-size: 26px;
}

.content{
  width: 100%;
  display: flex;
  height: 100%;
  max-height: 500px;
  min-height: 420px;
}
.menu{
  width: 40%;
  margin-right: 140px;
}
.title{
  width: 100%;
  height: 40px;
  background: #2C2D30;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  line-height: 40px;
  padding-left: 40px;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.btnBox{
  margin-top: 20px;
  display: flex;
}
.checkbox{
  height: 28px;
  width: 28px;
}
.commonEquipmentBox{
  width: 100%;
  height: 160px;
  background: #2C2D30;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.commonTitle{
  height: 40px;
  line-height: 40px;
  color: #fff;
  border-bottom: 1px solid #000;
  padding-left: 40px;
}
.commonItem{
  height: 50px;
  width: 100%;
  border-top: 1px solid rgb(53, 53, 53);
}
.popWindow{
  position: absolute;
  top: 0;
  left: 0;
}
</style>

