<template>
  <div class="btnBox" :style="{height: height + 'px',width: width+'px'}">
    <button class="btn" :class="[{disabled: disable},{btnred: color=='red'}]" :disabled='disable' :style="{height: height + 'px',width: width+'px'}"><slot></slot></button>
  </div>
  
</template>

<script>
export default {
  name:'BtnSubmit',
  props:{
    height:{
      type: Number,
      default: 40
    },
    width:{
      type: Number,
      default: 140
    },
    color:{
      type:String,
      default:'blue'
    },
    disable:{
      type: Boolean,
      default:false
    },
  }
}
</script>

<style scoped>
.btnBox{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0px auto;
}
#btn-submit{
  position: relative;
}
.btn{
  border: none;
  border-radius: 10px;
  color: aliceblue;
  font-size: 16px;
  font-weight: 900;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 20px auto;
  background: #0099FF;
  transition: .4s;
}
.btn:hover{
  background: #2cabff;
  cursor: pointer;
}
.disabled{
  background: cadetblue;
}
.btnred{
  background: #FF1057;
}
.btnred:hover{
  background: #e74676;
}
.disabled{
  background: #c9a8b2;
}
.disabled:hover{
  background: #c9a8b2;
}
</style>
