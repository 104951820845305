<template>
    <div id="validator" ref="validator">
        <slot></slot>
        <div id="insit">
            <div id="textBoxAB" ref="textBoxAB">
                <div id="textBox" v-show="show">
                    <div id="worringBox">
                        <span>{{Warring}}</span>
                    </div>
                    <div id="svgBox">
                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
  name:'Validator',
  components: {
    
  },
  props:{
      show:{
          type:Boolean,
          default:false
      },
      type:{
          type:String,
          default:""
      },
      type2:{
          type:String,
          default:""
      },
      value:{
          type:String,
          default:""
      },
      width:{
          type:String,
          default:"100%"
      },

      //下拉選單
      defaultName:{ //默認名
          type:String,
          default:""
      },
      titleName:{  //傳入的名
          type:[Array,Object,String,Number],
          default:""
      }
  },
  data() {
    return {
        Warring:"",
        isCheckOk:false,
    }
  },
    methods:{
        Check(){

            if(this.type == "notRequired"){
                if(this.type2 == "number"){
                    //數字校驗
                    var regNum5 = /^[0-9]*$/
                    if(this.value.length == 0){
                        this.Warring = ""
                        this.isCheckOK = true
                        this.$emit('isCheckOkValue', this.isCheckOK)
                    }
                    else if(!regNum5.test(this.value)){
                        this.Warring = "*只能為數字"
                        this.isCheckOK = false
                        this.$emit('isCheckOkValue', this.isCheckOK)
                    }
                    else if(this.value.length < 5){
                        this.Warring = "*不可小於5位數"
                        this.isCheckOK = false
                        this.$emit('isCheckOkValue', this.isCheckOK)
                    }
                    else if(this.value.length > 15){
                        this.Warring = "*不可大於15位數"
                        this.isCheckOK = false
                        this.$emit('isCheckOkValue', this.isCheckOK)
                    }
                    else{
                        this.Warring = ""
                        this.isCheckOK = true
                        this.$emit('isCheckOkValue', this.isCheckOK)
                    }
                }
                else{
                    var regNum3 = /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/
                    if(this.value.length > 0 && !regNum3.test(this.value)){
                        this.Warring = "*不為正確的網址"
                        this.isCheckOK = false
                        this.$emit('isCheckOkValue', this.isCheckOK)
                    }
                    else{
                        this.Warring = ""
                        this.isCheckOK = true
                        this.$emit('isCheckOkValue', this.isCheckOK)
                    }
                }
            }
            else{
                if(this.type == "text"){
                    //文字校驗
                    var regNum2 = /^[\u0391-\uFFE5A-Za-z]+$/
                    if(this.value.length == 0){
                        this.Warring = "*不可為空"
                        this.isCheckOK = false
                        this.$emit('isCheckOkValue', this.isCheckOK)
                    }
                    else if(!regNum2.test(this.value)){
                        this.Warring = "*只能為文字"
                        this.isCheckOK = false
                        this.$emit('isCheckOkValue', this.isCheckOK)
                    }
                    else if(this.value.length > 100){
                        this.Warring = "*不可大於100位數"
                        this.isCheckOK = false
                        this.$emit('isCheckOkValue', this.isCheckOK)
                    }
                    else{
                        this.Warring = ""
                        this.isCheckOK = true
                        this.$emit('isCheckOkValue', this.isCheckOK)
                    }
                }
                else{
                    if(this.type == "drowDownMeun"){
                        if(this.defaultName == this.titleName){
                            this.Warring = "*不可為空"
                            this.isCheckOK = false
                            this.$emit('isCheckOkValue', this.isCheckOK)
                        }
                        else{
                            this.Warring = ""
                            this.isCheckOK = true
                            this.$emit('isCheckOkValue', this.isCheckOK)
                        }
                    }
                    else{
                        if(this.type == "number"){
                            //數字校驗
                            var regNum4 = /^[0-9]*$/
                            if(this.value.length == 0){
                                this.Warring = "*不可為空"
                                this.isCheckOK = false
                                this.$emit('isCheckOkValue', this.isCheckOK)
                            }
                            else if(!regNum4.test(this.value)){
                                this.Warring = "*只能為數字"
                                this.isCheckOK = false
                                this.$emit('isCheckOkValue', this.isCheckOK)
                            }
                            else if(this.value.length < 5){
                                this.Warring = "*不可小於5位數"
                                this.isCheckOK = false
                                this.$emit('isCheckOkValue', this.isCheckOK)
                            }
                            else if(this.value.length > 15){
                                this.Warring = "*不可大於15位數"
                                this.isCheckOK = false
                                this.$emit('isCheckOkValue', this.isCheckOK)
                            }
                            else{
                                this.Warring = ""
                                this.isCheckOK = true
                                this.$emit('isCheckOkValue', this.isCheckOK)
                            }
                        }
                        else{
                            //是否為空
                            if(this.value == ""){
                                this.Warring = "*不可為空"
                                this.isCheckOk = false
                                this.$emit('isCheckOkValue', this.isCheckOk)
                            }
                            else{
                                this.Warring = ""
                                this.isCheckOk = true
                                this.$emit('isCheckOkValue', this.isCheckOk)
                            }
                        }
                    }
                }
            }
            
        },
        widthFountion(){
            this.$refs.textBoxAB.style = "width: "+ this.width +";"
        }
    },
  mounted() {
      this.Check()
      this.widthFountion()
  },
  watch:{
      "value"(){
          this.Check()
      },
      "titleName"(){
          this.Check()
      },
      "width"(){
          this.widthFountion()
      }
  }
}
</script>

<style scoped>
@import url(https://cdn.bootcss.com/font-awesome/5.13.0/css/all.css);
*{
 margin: 0;
 padding: 0;
 position: relative;
 font-family: Helvetica, "PingFang SC", "Microsoft Yahei", sans-serif;
}
#validator{
    /* background-color: royalblue; */
    display: inline-block;
    width: 100%;
    
}

#insit{
    height: 15px;
}
#textBoxAB{
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* border: tomato 1px solid; */
}
#textBox{
    display: flex;
    margin: 0 0 0 auto;
    height: 100%;
    
}
#worringBox{
    height: 100%;
    font-size: 12px;
    line-height: 15px;
    align-items: center;
    text-align: center;
    color: #FF0000;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    
}
#svgBox{
    height: 100%;
}
</style>
