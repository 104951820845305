<template>
  <div id="progresscirclebar">
    <div class="progressBox">
      <svg class="circleBox"
         :style="{height: size * 2.25 + 'px',width: size * 2.3+ 'px'}">
        <circle 
          :cx="size" 
          :cy="size" 
          :r="size" ></circle>
        <circle 
          :cx="size" 
          :cy="size" 
          :r="size" 
          :stroke-dashoffset = progressNumber></circle>
      </svg>
      <div class="number">
        <h1>{{value}}<span>%</span></h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'ProgressCircleBar',
  data() {
    return {
      progressNumber: Number
    }
  },
  props:{
    value: {
      type: Number,
      default: 50
    },
    size: {
      type: String,
      default: "70"
    }
  },
  mounted(){
    this.progressNumber = 440 - (440 * this.value) / 100
  },
}
</script>

<style scoped>
.progressBox{
  position: relative;
  width: 100%;
  height: auto;
  cursor: pointer;
}
.circleBox{
  position: relative;
  z-index: 1000;
}
.circleBox circle{
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #770f0f;
  stroke-width: 13;
  stroke-linecap: round;
  transform: translate(6px,6px);
  transition: .4s;
}

.circleBox circle:nth-child(2){
  stroke-dasharray: 440;
  stroke: #00ff43;
}
.number{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(185, 185, 185);
  border-radius: 50%;
  transition: .4s;
}
.progressBox:hover .number{
  font-size: 24px;
  color: #fff;
}
</style>
