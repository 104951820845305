import request from "../request"

export function getPersonalSetting() {
  return request({
    url: '/api/user/ucenter/user-profile/getPersonalSetting',
    method: 'get',
  })
}

export function updatePersonalSetting(perForm) {
  return request({
    url: '/api/user/ucenter/user-profile/updatePersonalSetting',
    method: 'put',
    data: perForm
  })
}
export function updateSecuritySetting(safeForm) {
  return request({
    url: '/api/user/ucenter/user-profile/updateSecuritySetting',
    method: 'put',
    data: safeForm
  })
}
export function getSecuritySetting() {
  return request({
    url: '/api/user/ucenter/user-profile/getSecuritySetting',
    method: 'get',
  })
}

export function uploadImg(file) {
  return request({
    
    url: 'admin/oss/file/upload?module=avatar',
    method: 'post',
    data: file
  })
}

export function removeImg(file) {
  return request({
    url: '/admin/oss/file/remove',
    method: 'delete',
    data: file
  })
}

// export function XuUploadImg(file,fileType) {
//   return request({
    
//     url: 'admin/oss/file/upload?module=' + fileType,
//     method: 'post',
//     data: file
//   })
// }
