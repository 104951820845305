<template>
  <div id="menuoptionitem" :style="{height: height+'px'}">
    <div class="title" :style="{width: widthTitle}"><h4><slot name="title"></slot></h4></div>
    <div class="content" :class="{textStart:textStart}"><slot name="value"></slot></div>
    <div class="btn"><slot name="btn"></slot></div>
  </div>
</template>

<script>
export default {
  name:'MenuOptionItem',
  components: {

  },
  props:{
    height:{
      type: String,
      default: "76"
    },
    textStart:{
      type: Boolean,
      default: false
    },
    widthTitle:{
      type: String,
      default: "14%"
    }
  },
  methods:{
    
  },
}
</script>

<style scoped>
#menuoptionitem{
  display: flex;
  align-items: center;
  color: aliceblue;
  justify-items: center;
  box-sizing: border-box;
}
.title{
  width: 14%;
  padding-left: 40px;
}
.content{
  width: 70%;
  display: flex;
  justify-content: center;
}
.btn{
  width: 10%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.textStart{
  text-align: start;
  justify-content: start;
}
</style>
