<template>
  <div class="selectBar">
    <div id="selectbtn" @click="btnActive" :class="{dropMenuActive:isActive}">
      <div class="text">{{title}}</div>
      <div class="icon" :class="{active:isActive}"><svg-icon name="down" color="#fff"></svg-icon></div>
    </div>
    <div class="menu" :class="{menuActive:isActive}">
      <slot></slot>
    </div>
  </div>

</template>

<script>
export default {
  name:'SelectBtn',
  components: {

  },
  data() {
    return {
      isActive: false
    }
  },
  props:{
    title: String,
  },
  methods:{
    btnActive(){
      this.isActive=!this.isActive
    }
  },
}
</script>

<style scoped>
#selectbtn{
  width: 100%;
  height: 36px;
  background: #48494E;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.text{
  text-align: center;
  width: 90%;
}
.active{
  transform: rotate(180deg);
}
.icon{
  transition: .4s;
}
.menu{
  position: absolute;
  height: 0;
  overflow: hidden;
  width: 100%;
  transition: .4s;
  max-height: 500px;
  background: #48494E;
  z-index: 1;
  border-radius: 10px;
  margin-top: 4px;
}
.menuActive{
  height: 300px;
}
.dropMenuActive{
  background: #3C7AD9;
  width: 90%;
  height: 40px;
  color: #EEEFF4;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.selectBar{
  position: relative;
  width: 100%;
}
</style>
