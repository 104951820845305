<template>
  <div id="popPasswordQuestion" v-if="popShow">
    <div class="mask" @click="cancel"></div>
    <div class="window">
      <div class="windowItem">
        <h1>Question</h1>
        <h3>密碼問題能夠更好的幫助您保護您的賬戶安全，並找回賬號，務必確認您在安全情況下填寫並牢記您設置的答案，我們需要通過您設置的答案確認您的身份。</h3>
        <h4>遇到問題？<a href="#">服務中心</a> </h4>
      </div>
      <div class="windowItem">
        <div class="content" v-if="isShow">
          <h2>為了保全您的帳號安全，您必須回答密碼問題</h2>
          <input-text :placeholder="placeholder" color="#fff" class="inputText"></input-text>
        </div>
        <div class="content" v-if="!isShow">
          <h2 class="textTitle">您可以設置新的密碼問題和答案</h2>
          <div class="selectBox">
            <Validator type="drowDownMeun" defaultName="Your Password Question" :titleName="form.btnText" :show="chickShow" @isCheckOkValue="btnTextCheck">
              <select-btn :title="form.btnText" ref="selectBtn" @click="getquestion">
                <div class="option" v-for="item in passwordQuestion" :key="item.index" @click="optionClick(item.enName)">{{item.enName}}</div>
              </select-btn>
            </Validator>
          </div>
          
          <div class="inputBox">
            <Validator type="text" :value="form.answer" :show="chickShow" @isCheckOkValue="answerCheck">
              <input-text :placeholder="placeholder" color="#fff" class="inputText" v-model="form.answer"></input-text>
            </Validator>
          </div>
          <div class="inputBox">
            <input-text :placeholder="placeholderAgain" color="#fff" class="inputText" v-model="form.answerAgain"></input-text>
            <div class="warring" v-if="chickShow">
              <span>{{warring}}</span>
            </div>
          </div>
        </div>
        <div class="controllBar">
          <btn-submit @click="isOk" color="red">Ok</btn-submit>
          <btn-submit @click="cancel">Cancel</btn-submit>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BtnSubmit from '@/components/button/BtnSubmit.vue'
import InputText from '@/components/XuComponents/form/InputText'
import SelectBtn from '@/components/form/SelectBtn.vue'
import Validator from '@/components/validator/Validator'

import { getDictById } from "@/axios/api/dict"
export default {
  name:'PopPasswordQuestion',
  components: {
    InputText,
    BtnSubmit,
    SelectBtn,
    Validator,
  },
  data() {
    return {
      placeholder: 'Please Enter Your Answer',
      placeholderAgain: 'Please Enter Your Answer Again',
      btnText:'Your Password Question',
      warring:"*必須與上一個答案一致",
      isShow: true,
      popShow: false,
      passwordQuestion: [],

      form:{
        btnText:'Your Password Question',
        answer:"",
        answerAgain:"",
      },
      isCheckAllOkForm:{
        answerCheck:false,
        answerAgainCheck:false,
        btnTextCheck:false,
      },

      //表單校驗顯示 與否
      chickShow:false,
    }
  },
  methods:{
    isOk(){
      this.chickShow = true
      this.answerAgainCheck()
      if(this.isCheckAllOkForm.answerCheck == true 
      && this.isCheckAllOkForm.answerAgainCheck == true 
      && this.isCheckAllOkForm.btnTextCheck == true ){
        // this.$emit('btnText', this.form.btnText)
        this.$emit('returnForm', this.form)
        this.zero()
        this.popShow=false
      }
      else{
        this.popShow=true
      }
    },
    cancel(){
      this.zero()
      this.popShow=false
    },

    optionClick(index){
      this.form.btnText = index
      this.$refs.selectBtn.isActive=false
    },
    getquestion(){
      getDictById(20000).then(res=>{
        // console.log(res.data)
        this.passwordQuestion = res.data.list
      })
    },
    
    //歸零
    zero(){
      this.form.btnText = "Your Password Question"
      this.form.answer = ""
      this.form.answerAgain = ""
      this.chickShow = false
    },
    //表單校驗
    answerCheck(CheckBoolen){
      this.isCheckAllOkForm.answerCheck = CheckBoolen
    },
    answerAgainCheck(){
      if(this.form.answerAgain !== this.form.answer){
        this.isCheckAllOkForm.answerAgainCheck = false
        this.warring="*必須與上一個答案一致"
      }
      else{
        this.isCheckAllOkForm.answerAgainCheck = true
        this.warring=""
      }
    },
    btnTextCheck(CheckBoolen){
      this.isCheckAllOkForm.btnTextCheck = CheckBoolen
    },
    
  },
  mounted(){
    getDictById(20000)
  },
  watch:{
    "form.answer"(){
      this.answerAgainCheck()
    },
    "form.answerAgain"(){
      this.answerAgainCheck()
    }
  }
}
</script>

<style scoped>
#popPasswordQuestion{
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}
.mask{
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: black;
  opacity: .7;
  z-index: 0;
  cursor: pointer;
}
.window{
  margin-left: 10%;
  width: 60%;
  height: 56%;
  background: #212024;
  z-index: 1;
  border-radius: 20px;
  display: flex;
}
.windowItem{
  width: 50%;
  padding-top: 30px;
  padding-left: 60px;
  color: aliceblue;
  box-sizing: border-box;
}
h3{
  margin-top: 20px;
  width: 80%;
  color: rgb(168, 168, 168);
  margin-bottom: 20px;
}
h4,h2{
  margin-top: 60px;
}
a{
  color: rgb(86, 86, 172);
}
.inputText{
  margin-top: 40px;
  width: 100%;
}
.controllBar{
  display: flex;
  margin-top: 40px;
}
.textTitle{
  margin-top: 30px;
  margin-bottom: 20px;
}
.option{
  width: 100%;
  line-height: 36px;
  padding-left: 10px;
  cursor: pointer;
}
.selectBox{
  width: 70%;
}
.inputBox{
  width: 80%;
  position: relative;
}
.warring{
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  text-align: right;
  color: #FF0000;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  
}
</style>
